import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Chip,
  Button,
  Typography,
  // TableContainer,
  // Tooltip,
} from "@mui/material";
// import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import NoRecordFound from "../../../../components/NoRecordFound";
import {
  firstCharacterUppercase,
  numberFormat,
  StringDateConversion,
} from "../../../../utils/Validations";
import { ErrorMsg } from "../../../../components/Messages/ErrorMsg";
// import { useCallback } from "react";

const chipColors: any = {
  completed: { bg: "#D8FBDE", text: "#007B55" },
  active: { bg: "#D8FBDE", text: "#007B55" },
  rejected: { bg: "rgba(255, 86, 48, 0.16)", text: "#B71D18" },
  expired: { bg: "rgba(255, 86, 48, 0.16)", text: "#B71D18" },
  cancelled: { bg: "rgba(255, 86, 48, 0.16)", text: "#B71D18" },
  default: { bg: "rgba(145, 158, 171, 0.16)", text: "#212B36" },
};

export default function PaymentRequestTable(props: any) {
  const paymentList = props?.props || [];
  const paymentSelectionType = props?.paymentIdentifier || "seller";
  const navigate = useNavigate();

  const getChipStyles = (status: string) => {
    const { bg, text } = chipColors[status] || chipColors.default;
    return {
      backgroundColor: bg,
      color: text,
      fontWeight: 700,
      fontSize: 12,
      lineHeight: "20px",
      fontFamily: "Public Sans",
      minHeight: "24px",
      minWidth: "32px",
      borderRadius: "8px",
      textTransform: "capitalize", // CSS property for uppercase first letter
    };
  };
  const handleViewRequestOnClickBuyerOrder = (item: any) => {
    if (item) {
      navigate("/dashboard/order", { state: { orderDetails: item } });
    } else {
      ErrorMsg("No Record Found !");
    }
  };

  const handleViewRequestOnClickSellerOrder = (item: any) => {
    if (item.isPr) {
      navigate("/dashboard/request", { state: { orderDetails: item } });
    } else {
      navigate("/dashboard/requestedorder", { state: { orderDetails: item } });
    }
  };

  const TableHeaderCell = ({
    children,
    textAlign = "left",
  }: {
    children: any;
    textAlign?: "left" | "center" | "right";
  }) => (
    <TableCell sx={{ borderBottom: "none", textAlign, width: "20%" }}>
      <Typography
        fontSize={14}
        fontWeight={600}
        fontFamily="Public Sans"
        color="grey"
      >
        {children}
      </Typography>
    </TableCell>
  );

  const TableCellWithTypography = ({
    children,
    fontSize = 13,
    color = "#212B36",
    fontWeight = 500,
    fontFamily = "Public Sans",
  }: {
    children: any;
    fontSize?: number;
    color?: string;
    fontWeight?: number;
    fontFamily?: string;
  }) => (
    <TableCell sx={{ borderBottom: "none" }}>
      <Typography
        fontSize={fontSize}
        color={color}
        fontFamily={fontFamily}
        fontWeight={fontWeight}
      >
        {children}
      </Typography>
    </TableCell>
  );

  return (
    <Table>
      <TableHead>
        <TableRow sx={{ backgroundColor: "#F7F9FB" }}>
          <TableHeaderCell>
            {paymentSelectionType === "buyer" ? "Seller" : "Buyer"}
          </TableHeaderCell>
          <TableHeaderCell>Payment Type</TableHeaderCell>
          <TableHeaderCell>Create On Date</TableHeaderCell>
          <TableHeaderCell>Payment Date</TableHeaderCell>
          <TableHeaderCell>Amount</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Action</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {paymentList.length > 0 ? (
          paymentList.map((item: any, index: number) => (
            <TableRow key={index}>
              {/* <TableCellWithTypography>
                {item.legalNameOfBusiness || "N/A"}
              </TableCellWithTypography> */}
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderBottom: "none",
                  // px: 3,
                }}
              >
                <Typography
                  fontSize={13}
                  color={"#303981"}
                  fontFamily={"Public Sans"}
                  fontWeight={700}
                >
                  {item.legalNameOfBusiness && item.legalNameOfBusiness !== ""
                    ? item.legalNameOfBusiness
                    : "N/A"}{" "}
                </Typography>
                <Typography
                  fontSize={12}
                  color={"#212B36"}
                  fontFamily={"Public Sans"}
                  fontWeight={500}
                >
                  Order No: {item && item.orderID !== "" ? item.orderID : "N/A"}{" "}
                </Typography>
                <Typography
                  fontSize={11}
                  color={"#212B36"}
                  fontFamily={"Public Sans"}
                >
                  {item && item.POPI !== "" ? item.POPI : "N/A"}{" "}
                </Typography>
              </TableCell>

              <TableCellWithTypography>
                {item.paymentType === "full"
                  ? "Full Payment"
                  : "Partial Payment"}
              </TableCellWithTypography>
              <TableCellWithTypography>
                {StringDateConversion(item.proposalCreatedDate) || "N/A"}
              </TableCellWithTypography>
              <TableCellWithTypography>
                {StringDateConversion(item.lastMilestoneDate) || "N/A"}
              </TableCellWithTypography>
              <TableCellWithTypography>
                {numberFormat(item.orderAmount) || "N/A"}
              </TableCellWithTypography>
              <TableCell sx={{ borderBottom: "none", textAlign: "left" }}>
                <Chip
                  label={firstCharacterUppercase(item.status)}
                  sx={getChipStyles(item.status)}
                />
              </TableCell>
              <TableCell sx={{ borderBottom: "none" }}>
                <Button
                  size="small"
                  onClick={() =>
                    paymentSelectionType === "buyer"
                      ? handleViewRequestOnClickBuyerOrder(item)
                      : handleViewRequestOnClickSellerOrder(item)
                  }
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Public Sans",
                    backgroundColor: "",
                    color: "var(--text-light-primary, #212B36)",
                    borderRadius: "8px",
                    fontWeight: 700,
                    px: "8px",
                    py: "4px",
                    gap: "8px",
                    lineHeight: "22px",
                    border: "1px solid rgba(145, 158, 171, 0.32)",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#36B37E",
                      color: "white",
                    },
                  }}
                >
                  View
                </Button>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={7} align="center" sx={{ borderBottom: "none" }}>
              <NoRecordFound text1={"Payments"} text2={"payments"} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
