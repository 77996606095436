import React from "react";
import Orders from "../../Common/ViewOrder/Main";

const SellerOrder = () => {
  const parentIdentifier = "seller";
  const subIdentifier = "Orders";
  return (
    <div>
      <Orders
        parentIdentifier={parentIdentifier}
        subIdentifier={subIdentifier}
      />
    </div>
  );
};

export default SellerOrder;
