import React from "react";
import Orders from "../../Common/ViewOrder/Main";
import PaymentRequest from "../../Common/PaymentRequest/Main";

const PaymentRequestBuyer = () => {
  const parentIdentifier = "buyer";
  const subIdentifier = "Payment Request";
  return (
    <div>
      <PaymentRequest
        parentIdentifier={parentIdentifier}
        subIdentifier={subIdentifier}
      />
    </div>
  );
};

export default PaymentRequestBuyer;
