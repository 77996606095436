import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography, Button } from "@mui/material";
import NoRecordFound from "../../../components/NoRecordFound";
import { numberFormat, splitDateConversion } from "../../../utils/Validations";
import { useNavigate } from "react-router-dom";
import { fontSizes } from "../../../utils/FontSize";
export default function AvailableRecievablesList(props: any) {
  const navigate = useNavigate();
  const AvailablePaymentList = props ? props.props : [];
  const handleViewRequestOnClick = async (item: any) => {
    navigate("/dashboard/recievableorder", {
      state: { OrderDetails: item },
    });
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#F7F9FB" }}>
            <TableCell
              sx={{
                borderBottom: "none",
                px: 4,
              }}
            >
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Order ID / Date
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Business Name
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Amount
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Platform Fees
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Assured Receivables
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Spendable
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Used
              </Typography>
            </TableCell>

            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Action
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {AvailablePaymentList.length > 0 &&
            AvailablePaymentList.map((item: any, index: any) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    key={index}
                    component="th"
                    scope="row"
                    sx={{
                      borderBottom: "none",
                      px: 3,
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                      fontWeight={600}
                    >
                      {item.orderID}
                    </Typography>
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                      // fontWeight={600}
                    >
                      {/* miles stone settelment Date */}
                      {splitDateConversion(item.settlementDate)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"#303981"}
                      fontFamily={"Public Sans"}
                      fontWeight={600}
                      maxWidth={"400px"}
                    >
                      {item.Legal_Name_of_Business !== ""
                        ? item.Legal_Name_of_Business
                        : "N/A"}{" "}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                    >
                      {item.amount ? numberFormat(item.amount) : "N/A"}{" "}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"red"}
                      fontFamily={"Public Sans"}
                    >
                      {item.platformFee > 0
                        ? numberFormat(item.platformFee.toFixed(2))
                        : 0}{" "}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"green"}
                      fontFamily={"Public Sans"}
                    >
                      {numberFormat(item.amount - item.platformFee || 0)}{" "}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"green"}
                      fontFamily={"Public Sans"}
                    >
                      {numberFormat(item.spendableAmount) || 0}{" "}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"green"}
                      fontFamily={"Public Sans"}
                    >
                      {item.amount -
                        (item.platformFee || 0) -
                        (item.spendableAmount || 0) || 0}{" "}
                    </Typography>
                  </TableCell>

                  {/* <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                    >
                      {item.totalutilisedbySeller > 0
                        ? item.totalutilisedbySeller.toFixed(2)
                        : 0}{" "}
                    </Typography>
                  </TableCell> */}
                  {/* <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                    >
                      {item.totalutilisedbySeller > 0
                        ? item.totalutilisedbySeller.toFixed(2)
                        : 0}{" "}
                    </Typography>
                  </TableCell> */}
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Button
                      size="small"
                      onClick={() => handleViewRequestOnClick(item)}
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Public Sans",
                        backgroundColor: "",
                        color: "var(--text-light-primary, #212B36)",
                        borderRadius: "8px",
                        fontWeight: 700,
                        fontStyle: "normal",
                        border: "1px solid  rgba(145, 158, 171, 0.32)",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "green",
                          color: "white",
                        },
                      }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {AvailablePaymentList.length <= 0 ? (
        <NoRecordFound
          text1={"Available Receivables"}
          text2={"available receivables"}
        />
      ) : (
        ""
      )}
    </>
    // </TableContainer>
  );
}
