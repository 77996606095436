import React from "react";
import Orders from "../../Dashboard/Orders";
import ApiService from "../../../../api/api";
import { useSelector } from "react-redux";
import PaymentRequest from "../../Common/PaymentRequest/Main";

const PaymentRequestSeller = () => {
  let token: string = useSelector((state: any) =>
    state.user.Token ? state.user.Token : ""
  );
  const parentIdentifier = "seller";
  const subIdentifier = "Payment Request";
  return (
    <div>
      <PaymentRequest
        parentIdentifier={parentIdentifier}
        subIdentifier={subIdentifier}
      />
    </div>
  );
};

export default PaymentRequestSeller;
