import React from "react";
import Orders from "../../Common/ViewOrder/Main";

const orders = () => {
  const parentIdentifier = "buyer";
  const subIdentifier = "Orders";
  return (
    <div>
      <Orders
        parentIdentifier={parentIdentifier}
        subIdentifier={subIdentifier}
      />
    </div>
  );
};

export default orders;
