import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import {
  daysConversionFromDate,
  numberFormat,
} from "../../../../utils/Validations";
import {
  Typography12,
  Typography16,
} from "../../../../components/typography/Typography";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SuccessMsg } from "../../../../components/Messages/SuccessMsg";
import { ErrorMsg } from "../../../../components/Messages/ErrorMsg";
import { Icon } from "@iconify/react";
import ApiService from "../../../../api/api";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#303981",
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  width: 400,
  boxShadow: 24,
  borderRadius: 3,
  p: 2,
};

function ConfirmationPage({
  handleStepper,
  confirmDataForPayment,
  paymentSummaryData,
  requestToPayData,
  isSteplock,
}: any) {
  const newToken = useSelector((state: any) => state.user.Token);
  // const REACT_APP_BASE_URL =
  //   process.env.REACT_APP_BASE_URL && process.env.REACT_APP_BASE_URL;
  const REACT_APP_BASE_URL = "https://dev.api.assuredpay.in";

  const [checkBox, setcheckBox] = React.useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = React.useState(false);
  const navigate = useNavigate();

  //modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const makePayment = async () => {
    setIsPaymentLoading(true);
    let body = {
      orderDetails: {
        business_id: isSteplock
          ? requestToPayData.order.createdBy
          : confirmDataForPayment?.orderDetails?.business_id,
        paymentType:
          confirmDataForPayment.orderDetails.paymentType === "Full Payment"
            ? "full"
            : "partial", //"full"/"partial",
        paymentCombination:
          confirmDataForPayment.orderDetails.paymentCombination,
        remark: confirmDataForPayment.orderDetails.remaks,
        orderType: confirmDataForPayment.orderDetails.orderType,
        title: confirmDataForPayment.orderDetails.title,
        POPI: confirmDataForPayment.orderDetails.POPI,
        orderAmount: +confirmDataForPayment.orderDetails.orderAmount,
        paymentIdentifier: "buyer",
        paymentDays:
          confirmDataForPayment.orderDetails.paymentType === "Partial Payment"
            ? confirmDataForPayment?.milestoneDetails[
                confirmDataForPayment?.milestoneDetails.length - 1
              ].days
            : daysConversionFromDate(paymentSummaryData.date),
        fromPR: isSteplock ? true : confirmDataForPayment.orderDetails.fromPR,
        prId: isSteplock ? requestToPayData.order._id : undefined,
        paymentSettlementDate:
          confirmDataForPayment.orderDetails.paymentSettlementDate,
      },
      milestoneDetails: confirmDataForPayment.milestoneDetails,
    };
    const verifyToken = await ApiService.setToken(newToken);
    const responseData: any = await ApiService.post(
      `/checkerRoutes/createprchecker`,
      body
    );
    if (responseData.status === 200) {
      setIsPaymentLoading(false);
      navigate("/dashboard/order", {
        state: {
          orderDetails: responseData.data.result.order,
          GeneratePayment: "buyer",
        },
      });
      handleClose();
    } else {
      setIsPaymentLoading(false);
    }
  };

  const handleCopyClick = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      SuccessMsg("Copied!");
    } catch (err) {
      ErrorMsg("Unable to copy to clipboard.");
    }
  };

  return (
    <>
      <Grid
        m={4}
        rowGap={1}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(3, 1fr)",
        }}
      >
        <Stack>
          <Typography variant="subtitle2" sx={{ color: "#b0bac4" }}>
            Order Title
          </Typography>
          <Typography variant="h6">
            {confirmDataForPayment?.orderDetails?.title}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle2" sx={{ color: "#b0bac4" }}>
            Payment Type
          </Typography>
          <Typography variant="h6">
            {paymentSummaryData.paymentType === "payment_against_invoice"
              ? "Payment Against Invoice"
              : paymentSummaryData.paymentType === "advanced_payment"
              ? "Advance Payment"
              : "Payment on Account"}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle2" sx={{ color: "#b0bac4" }}>
            Order Type
          </Typography>
          <Typography variant="h6">
            {paymentSummaryData?.paymentMethod}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle2" sx={{ color: "#b0bac4" }}>
            PO/PI (Mandatory)
          </Typography>
          <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
            <Typography variant="h6">{paymentSummaryData.PO}</Typography>
            <Icon
              icon="mingcute:copy-line"
              style={{ color: "#707070", fontSize: 20, cursor: "pointer" }}
              onClick={() => handleCopyClick(paymentSummaryData.PO)}
            />
          </Stack>
        </Stack>
        <Stack>
          <Typography variant="subtitle2" sx={{ color: "#b0bac4" }}>
            Invoice Amount
          </Typography>
          <Typography variant="h6">
            {numberFormat(paymentSummaryData?.amount)}
          </Typography>
        </Stack>
        {paymentSummaryData?.paymentMethod === "Full Payment" ? (
          <Stack>
            <Typography variant="subtitle2" sx={{ color: "#b0bac4" }}>
              Payment Days
            </Typography>
            <Typography variant="h6">
              {daysConversionFromDate(paymentSummaryData?.date)}
            </Typography>
          </Stack>
        ) : (
          <Stack>
            <Typography variant="subtitle2" sx={{ color: "#b0bac4" }}>
              Milestones
            </Typography>
            <Typography variant="h6">
              {paymentSummaryData?.milestone}
            </Typography>
          </Stack>
        )}
        <Stack>
          <Typography variant="subtitle2" sx={{ color: "#b0bac4" }}>
            AP Balance for Used
          </Typography>
          <Typography variant="h6">
            {numberFormat(
              confirmDataForPayment?.milestoneDetails?.reduce(
                (total: number, item: any) => total + item.usingBB,
                0
              )
            )}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle2" sx={{ color: "#b0bac4" }}>
            Used Receivables
          </Typography>
          <Typography variant="h6">
            {numberFormat(
              confirmDataForPayment?.interestTable?.reduce(
                (total: number, item: any) =>
                  !item.amountType && total + item.amount,
                0
              )
            )}
          </Typography>
        </Stack>
      </Grid>

      {/* Interest Table */}
      <Card
        sx={{
          m: 3,
          borderRadius: 4,
          borderStyle: "dashed",
          borderColor: "#d4dadf",
          backgroundColor: "#dfe3e8",
        }}
      >
        {confirmDataForPayment?.interestTable?.length > 0 && (
          <Stack sx={{ m: 2, borderRadius: 4 }}>
            <Typography
              fontFamily={"Public Sans"}
              fontWeight={700}
              variant="h5"
              my={1}
            >
              Interest Details
            </Typography>
            <Card sx={{ borderRadius: 4 }}>
              <TableContainer>
                <Table sx={{ width: "100%" }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Amount Type</StyledTableCell>
                      <StyledTableCell>Amount</StyledTableCell>
                      <StyledTableCell>Days</StyledTableCell>
                      <StyledTableCell>Fees</StyledTableCell>
                      <StyledTableCell>Interest</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {confirmDataForPayment?.interestTable.map((row: any) => (
                      <StyledTableRow key={row._id}>
                        <StyledTableCell component="th" scope="row">
                          {row.amountType ? "Bank Balance" : "Receivables"}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={
                            !row.amountType
                              ? { color: "#F58336" }
                              : { color: "black" }
                          }
                        >
                          {row.amount}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.paymentDays} Days
                        </StyledTableCell>
                        <StyledTableCell>{row.convenienceFees}</StyledTableCell>
                        <StyledTableCell sx={{ color: "green" }}>
                          {row.approxInterest}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
            <Typography variant="body1" fontFamily={"Public Sans"} my={2}>
              Disclaimer
            </Typography>
            <Typography
              variant="caption"
              fontWeight={500}
              fontFamily={"Public Sans"}
            >
              The displayed Fixed Deposit interest rates are current as of this
              moment and are subject to change. Rates are updated every 15
              seconds to provide you with the most accurate information. Please
              note that the actual interest rates applicable to your Fixed
              Deposit may differ at the time of confirmation. We recommend
              verifying the rates before finalizing any transactions or
              investments.
            </Typography>
            <Stack>
              <Typography
                variant="h6"
                color={"#F58336"}
                noWrap
                textAlign={"end"}
                mr={2}
              >
                Total Fees :{" "}
                {parseFloat(
                  confirmDataForPayment?.interestTable.reduce(
                    (total: number, item: any) => total + item.convenienceFees,
                    0
                  )
                ).toFixed(2)}
              </Typography>
              <Typography
                variant="h6"
                color={"green"}
                noWrap
                textAlign={"end"}
                mr={2}
              >
                Total Interest :{" "}
                {parseFloat(
                  confirmDataForPayment?.interestTable.reduce(
                    (total: number, item: any) => total + item.approxInterest,
                    0
                  )
                ).toFixed(2)}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Card>

      <Stack flexDirection={"row"} justifyContent={"space-between"} m={3}>
        <Button sx={{ color: "#303981" }} disabled>
          Previous
        </Button>
        <Stack flexDirection={"row"} gap={1}>
          <Button
            variant="contained"
            sx={{
              background: "#303981",
              borderRadius: 2,
              "&:hover": {
                backgroundColor: "#343d84db",
              },
            }}
            onClick={() => handleStepper(0)}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#36B37E",
              borderRadius: 2,
              "&:hover": {
                backgroundColor: "#343d84db",
              },
            }}
            onClick={handleOpen}
          >
            Make Payment
          </Button>
        </Stack>
      </Stack>

      {/* Make payment confirmation */}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack flexDirection={"row"} justifyContent={"center"} mb={2}>
            <Typography16 color={"#212B36"} text={"Disclaimer"}></Typography16>
          </Stack>
          <Stack direction={"row"}>
            <Box>
              <Checkbox
                value={checkBox}
                onChange={() => setcheckBox(!checkBox)}
                sx={{
                  color: "#303981",
                  "&.Mui-checked": {
                    color: "#303981",
                  },
                }}
              />
            </Box>
            <Typography12
              color={!checkBox ? "#212B36" : "#303981"}
              fontWeight={500}
              text={
                "Once you make the payment, it is crucial to understand that the transaction is irreversible."
              }
            ></Typography12>{" "}
          </Stack>
          <Stack flexDirection={"row"} justifyContent={"end"} mt={2} gap={1}>
            <Button
              sx={{
                borderRadius: 2,
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              sx={{
                background: "#303981",
                borderRadius: 2,
                "&:hover": {
                  backgroundColor: "#343d84db",
                },
              }}
              onClick={makePayment}
              loading={isPaymentLoading}
              disabled={!checkBox}
            >
              Confirm
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
export default React.memo(ConfirmationPage);
